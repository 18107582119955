import { Link } from 'gatsby'
import React from 'react'
import Whatsapp from './Whatsapp'

const Banner = () => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1 style={{ position: 'relative' }}>
          <Link to="/register">Online</Link> Homoeopathy{' '}
          <span className="bannerSubs">
            <sup>within earshot</sup>
          </span>
          {/* <span className="icon fa-whatsapp" style={{ color: '#25D366' }}>
            <span className="label">Twitter</span>
          </span>
          &nbsp;7356714004 */}
        </h1>
      </header>
      <div className="content">
        <p>
          Providing classical homoeopathic care <br />
          at the comfort of your home.
        </p>
        <ul className="actions">
          <li>
            <Whatsapp
              classnames="button next scrolly"
              label="7356714004"
              phone="7356714004"
              text={`Hello%20Doctor,%20I'm%20looking%20for%20medical%20advice%20on%20...`}
              icon={true}
            />
          </li>
        </ul>
        <p>or give us a missed call</p>
      </div>
    </div>
  </section>
)

export default Banner
