import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import { Seo } from '../components/Seo'
import aboutUs from '../assets/images/aboutUs.jpeg'

// import pic01 from '../assets/images/pic01.jpg'
// import pic02 from '../assets/images/pic02.jpg'
// import pic03 from '../assets/images/pic03.jpg'
import pic03 from '../assets/images/hand-painted-bg.jpg'
// import pic04 from '../assets/images/pic04.jpg'
import pic04 from '../assets/images/stethoscope-new.jpg'
import { handleWebHook } from '../utils'
// import pic05 from '../assets/images/pic05.jpg'
// import pic06 from '../assets/images/pic06.jpg'

export default function HomeIndex() {
  return (
    <Layout>
      <Seo />
      <Banner />
      <section
        id="aboutUs2"
        className="style1"
        style={
          {
            // backgroundImage: `url(${aboutUs})`,
            // backgroundPosition: 'top center',
            // backgroundRepeat: 'no-repeat',
          }
        }
      >
        <div className="inner">
          <header className="major">
            <h2>About Us</h2>
          </header>
          <p>
            We are a dedicated bunch for your wellbeing and resilience.
            <br />
            Our online presence provide best quality homoeopathic care at the
            comfort of your home and medicines are delivered at your doorstep
          </p>
          <h3>Why Online consultation ?</h3>
          <ul>
            <li>
              You can avoid unwanted hospital visits which is again a source of
              infection but, always rush to the hospital incases of emergency.
            </li>
            <li>
              Best quality treatment and doctors to help you only a call away.
            </li>
            <li>Less time consuming</li>
            <li>
              Patients who are on long term medication like diabetics,
              Hypertension and other diseases who need frequent doctor
              consultations can alternate between virtual and physical clinic
              visits.
            </li>
            <li>Medicines a door away with no additional costs</li>
          </ul>
          <ul className="actions">
            <li>
              <Link
                to="/process"
                className="button next"
                onClick={() =>
                  handleWebHook({ text: 'Someone just clicked know more' })
                }
              >
                Know More
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <div id="main">
        <section id="one" className="tiles">
          <article style={{ backgroundImage: `url(${pic03})` }}>
            <header className="major">
              <h3>Good Reads</h3>
              <p>bloomB Journals</p>
            </header>
            <Link to="/journals" className="link primary"></Link>
          </article>
          <article style={{ backgroundImage: `url(${pic04})` }}>
            <header className="major">
              <h3>Ask a Doctor</h3>
              <p>bloomB Forum</p>
            </header>
            <Link to="/forum" className="link primary"></Link>
          </article>
          <article style={{ backgroundImage: `url(${pic03})` }}>
            <header className="major">
              <h3>Team</h3>
              <p>bloomB Family</p>
            </header>
            <Link to="/team" className="link primary"></Link>
          </article>
          <article style={{ backgroundImage: `url(${pic04})` }}>
            <header className="major">
              <h3>Subscribe</h3>
              <p>Join bloomB</p>
            </header>
            <Link to="/subscribe" className="link primary"></Link>
          </article>
          {/* <article style={{ backgroundImage: `url(${pic05})` }}>
            <header className="major">
              <h3>Consequat</h3>
              <p>Ipsum dolor sit amet</p>
            </header>
            <Link to="/landing" className="link primary"></Link>
          </article>
          <article style={{ backgroundImage: `url(${pic06})` }}>
            <header className="major">
              <h3>Etiam</h3>
              <p>Feugiat amet tempus</p>
            </header>
            <Link to="/landing" className="link primary"></Link>
          </article> */}
        </section>
        {/* <section id="two">
          <div className="inner">
            <header className="major">
              <h2>Massa libero</h2>
            </header>
            <p>
              Nullam et orci eu lorem consequat tincidunt vivamus et sagittis
              libero. Mauris aliquet magna magna sed nunc rhoncus pharetra.
              Pellentesque condimentum sem. In efficitur ligula tate urna.
              Maecenas laoreet massa vel lacinia pellentesque lorem ipsum dolor.
              Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis
              libero. Mauris aliquet magna magna sed nunc rhoncus amet pharetra
              et feugiat tempus.
            </p>
            <ul className="actions">
              <li>
                <Link to="/landing" className="button next">
                  Get Started
                </Link>
              </li>
            </ul>
          </div>
        </section> */}
      </div>
    </Layout>
  )
}
